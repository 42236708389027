import clienteAxios from '../../config/axios';

const LoginUsuarioBD = async (modelo) =>
  clienteAxios.post('/Seguridad/login', {
    NomUsuario: modelo.NomUsuario,
    ClaUsuario: modelo.ClaUsuario,
  });

const ObtenerRegiones = async (modelo) =>
  clienteAxios.post('/Mant/ObtRegiones', {
    IdRegion: modelo.IdRegion,
    NomRegion: modelo.NomRegion.trim(),
  });

export { LoginUsuarioBD, ObtenerRegiones };
