import { getCurrentUser } from 'helpers/Utils';
import React from 'react';
import UserContextData from './userContextData';

const UserProvider = ({ children }) => {
  const currentUser = getCurrentUser();
  const user = currentUser;

  return (
    <UserContextData.Provider value={{ user }}>
      {children}
    </UserContextData.Provider>
  );
};

export default UserProvider;
