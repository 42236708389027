import axios from 'axios';

const clienteAxios = axios.create({
  baseURL: 'http://rodplanas-001-site2.etempurl.com',
  // baseURL: 'https://localhost:44308',
});

const token = localStorage.getItem('token');
// console.log(token);
// const token =
//   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImp0aSI6IjFhMTE5ZjQ0LWRhZjctNDAyNy1hZDkzLWIzNmE1MDA2NzhiMCIsImV4cCI6MTYyMzM3ODc1OSwiaXNzIjoiYXNocHJvZ2hlbHAuY29tIiwiYXVkIjoiYXNocHJvZ2hlbHAuY29tIn0.svxDW3X-4tBnO25NYrZOQbZtsfBo3oQYr0I0gdtFuz0';
clienteAxios.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default clienteAxios;
